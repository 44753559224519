import {
    PortableText,
    PortableTextComponents,
    PortableTextTypeComponent
} from '@portabletext/react' 
import React, { FunctionComponent } from 'react'
import {
    CTA,
    FeatureList,
    MainImage,
    MainImageComponentData,
    ProjectColours,
    TitleBlock
}                                   from '../../../index'

import {
    CallToAction,
    EventSelector,
    FeatureList as IFeatureList,
    FileUpload,
    TitleBlock as ITitleBlock,
    YouTube as IYouTube
}                 from '../../entities/schema'
import EventsList from '../EventsListInRichText'
import './portableText.css'

interface PortableTextProps {
    projectColour: ProjectColours
    blocks: any
    className?: string
}

export const setProjectColourStyles = (colour, background, includeHover) => {
    switch (colour) {
        case 'blue':
            return `${background ? 'bg-blue-500 text-white' : 'text-blue-500'} ${includeHover ? 'hover:text-white hover:bg-blue-700' : ''}`
        case 'red':
            return `${background ? 'bg-red-500 text-white' : 'text-red-500'} ${includeHover ? 'hover:text-white hover:bg-red-700' : ''}`
        case 'green':
            return `${background ? 'bg-green-500 text-white' : 'text-green-500'} ${includeHover ? 'hover:text-white hover:bg-green-700' : ''}`
        case 'purple':
            return `${background ? 'bg-purple-600 text-white' : 'text-purple-500'} ${includeHover ? 'hover:text-white hover:bg-purple-700' : ''}`
        case 'black':
            return `${background ? 'bg-black text-white' : 'text-grey-500'} ${includeHover ? 'hover:text-white hover:bg-grey-700' : ''}`
        case 'white':
            return `${background ? 'bg-white text-white' : 'text-grey-500'} ${includeHover ? 'hover:text-white hover:bg-grey-700 text-black' : ''}`
        case 'text-theme-grey-light':
            return 'text-theme-grey-light'
        case 'text-theme-gray':
            return 'text-theme-gray'
        case 'text-theme-blue-darkest':
            return 'text-theme-blue-darkest'
        case 'text-white':
            return 'text-white'
        case 'text-theme-blue':
            return 'text-theme-blue'
        case 'text-theme-teal':
            return 'text-theme-teal'
        case 'text-theme-red':
            return 'text-theme-red'
        case 'text-theme-dark-green':
            return 'text-theme-dark-green'
        case 'text-theme-green':
            return 'text-theme-green'
        case 'text-theme-orange':
            return 'text-theme-orange'
        case 'text-theme-purple':
            return 'text-theme-purple'
        case 'text-black':
            return 'text-black'
        default:
            return `${background ? 'bg-black text-white' : 'text-grey-500'} ${includeHover ? 'hover:text-white hover:bg-blue-700' : ''}`
    }
}

const YouTubeEmbed = ({node}) => {
    // console.log('Youtube embed: ', node)

    return node?.videoUrl ? (
        <section style={{
            margin       : '2rem 2rem',
            position     : 'relative',
            paddingBottom: '56.25%',
            height       : 0,
            overflow     : 'hidden',
            maxWidth     : '100%'
        }}>
            <iframe style={{
                position: 'absolute',
                top     : 0,
                left    : 0,
                width   : '100%',
                height  : '100%'
            }}
                    width="100%"
                    height="600"
                    src={node.videoUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
        </section>
    ) : null
}

const useSerialisers: (node: PortableTextTypeComponent<ITitleBlock | IFeatureList>) => PortableTextComponents = (projectColour) => {
    return {
        block   : {
            h1: (node) => {
                return <h1 className="text-3xl text-gray-800 font-semibold tracking-wide uppercase mt-10 mb-2">
                    {
                        node.value.children.map((t) => t.text)
                            .join()
                    }
                </h1>
            },
            h2: (node) => {
                return <h2 className="text-2xl text-gray-800 font-semibold tracking-wide mt-10 mb-2">
                    {
                        node.value.children.map((t) => t.text)
                            .join()
                    }
                </h2>
            },
            h3: (node) => {
                return <h3 className="text-xl text-gray-800 font-semibold tracking-wide mt-10 mb-2">
                    {
                        node.value.children.map((t) => t.text)
                            .join()
                    }
                </h3>
            },
            h4: (node) => {
                return <h4 className="text-base text-gray-800 font-semibold tracking-wide mt-10 mb-2">
                    {
                        node.value.children.map((t) => t.text)
                            .join()
                    }
                </h4>
            },
            h5: (node) => {
                return <h4 className="text-base text-gray-800 font-medium tracking-wide mt-10 mb-2">
                    {
                        node.value.children.map((t) => t.text)
                            .join()
                    }
                </h4>
            },
            h6: (node) => {
                return <h4 className="text-base italic text-gray-800 font-medium tracking-wide mt-10 mb-2">
                    {
                        node.value.children.map((t) => t.text)
                            .join()
                    }
                </h4>
            }
        },
        types   : {
            titleBlock   : (node) => {
                const data = node.value as ITitleBlock
                return <TitleBlock data={data}/>
            },
            featureList  : (node) => {
                const data = node.value as IFeatureList
                return <FeatureList featureListData={data}
                                    projectColour={projectColour}/>
            },
            youTube      : (node) => {
                const data = node.value as IYouTube
                return <YouTubeEmbed node={data}/>
            },
            image        : (node) => {
                const data = node.value as MainImageComponentData
                return <MainImage mainImage={data}/>
            },
            mainImage    : (node) => {
                const data = node.value as MainImageComponentData
                return <MainImage mainImage={data}/>
            },
            eventSelector: (node) => {
                const data = node.value as EventSelector
                // console.log('Event selector: ', data)
                return <EventsList data={data}/>
            },
            callToAction : (node) => {
                const data = node.value as CallToAction
                return <CTA cta={data}/>
            },
            fileUpload   : (node) => {
                const data  = node.value as FileUpload
                const asset = data.asset as unknown as { url: string, extension: string } // type for sanity asset
                                                                                          // doesn't exist in codegen
                                                                                          // library
                return <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                        <div className="ml-4 mt-4">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Download
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                                {data.title}
                            </p>
                        </div>
                        <div className="ml-4 mt-4 flex-shrink-0">
                            <a href={asset.url} target={'_blank'}
                               className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                Download {asset.extension.toUpperCase()}
                            </a>
                        </div>
                    </div>
                </div>
            }
        },
        marks   : {
            intLink: ({mark, children}) => {
                const ref = mark.article
                // TODO: handle case where internal link is to article that is linked to another topic, get the slug
                //       from the mark.article.topics array and use instead of the current slug.
                return (
                    <a className={setProjectColourStyles(projectColour, false, false)}
                       href={ref ? ref.slug.current : '/'}><span
                        className="internal-article-link">{children}</span>
                    </a>)
            },
            link   : (node) => {
                const {value, text} = node
                return value.blank ? (
                    <a className={setProjectColourStyles(projectColour, false, false)} href={value.href}>{text}</a>
                ) : (
                           <a className={setProjectColourStyles(projectColour, false, false)} href={value.href}
                              target="_blank" rel="noopener noreferer">{text}</a>
                       )
            },
            textCentre   : (node) => {
                return (
                    <span className="flex justify-center"><span>{node.children}</span></span>
                )
            }
        },
        list    : {
            bullet: (node) => {
                return <ul>
                    {node.children}
                </ul>
            },
            number: (node) => {
                return <ol>
                    {node.children}
                </ol>
            }
        },
        listItem: {
            number: (node) => {
                return (
                    <li>{node.children}</li>
                )
            },
            bullet: (props) => {
                return (
                    <li>{props.children}</li>
                )
            }
        }

    }
}

const PortableTextRenderer: FunctionComponent<PortableTextProps> = (props) => {

    const {className = 'portable-text', projectColour, blocks} = props
    const serialiser                                           = useSerialisers(projectColour)
    const clientConfig                                         = {
        projectId: process.env.GATSBY_SANITY_PROJECT_ID || '81k3cw4t',
        dataset  : process.env.GATSBY_SANITY_DATASET || 'production'
    }

    const handleMissingComponent = (message, options) => {
        // console.log(`Node not being rendered: ${message} - ${options}`)
    }

    return (
        <div className={[className, projectColour].join(' ')}>
            <PortableText onMissingComponent={handleMissingComponent} value={blocks} components={serialiser}/>
        </div>
    )

}

export { PortableTextRenderer }
